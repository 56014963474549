@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-range {
  vertical-align: middle;
  background-color: transparent;
  padding-top: 8px;
  padding-top: 0.5rem;
  padding-bottom: 8px;
  padding-bottom: 0.5rem;
  color: inherit;
  background-color: transparent;
  -webkit-appearance: none;
}

.input-range::-webkit-slider-thumb {
  position: relative;
  width: 8px;
  width: 0.5rem;
  height: 20px;
  height: 1.25rem;
  cursor: pointer;
  margin-top: -8px;
  margin-top: -0.5rem;
  border-radius: 3px;
  background-color: currentcolor;
  -webkit-appearance: none;
}

/* Touch screen friendly pseudo element */
.input-range::-webkit-slider-thumb:before {
  content: '';
  display: block;
  position: absolute;
  top: -8px;
  top: -0.5rem;
  left: -14px;
  left: -0.875rem;
  width: 36px;
  width: 2.25rem;
  height: 36px;
  height: 2.25rem;
  opacity: 0;
}

.input-range::-moz-range-thumb {
  width: 8px;
  width: 0.5rem;
  height: 20px;
  height: 1.25rem;
  cursor: pointer;
  border-radius: 3px;
  border-color: transparent;
  border-width: 0;
  background-color: currentcolor;
}

.input-range::-webkit-slider-runnable-track {
  height: 4px;
  height: 0.25rem;
  cursor: pointer;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.25);
}

.input-range::-moz-range-track {
  height: 4px;
  height: 0.25rem;
  cursor: pointer;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.25);
}

.input-range:focus {
  outline: none;
}

body {
  background-color: black;
  cursor: move;
}

[data-reach-dialog-overlay] {
  z-index: 999;
}

:root {
  --material-white: #fff;
  --material-gray: #898ba3;
}

