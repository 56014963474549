@import url('https://rsms.me/inter/inter.css');

body {
  background-color: black;
  cursor: move;
}

[data-reach-dialog-overlay] {
  z-index: 999;
}

:root {
  --material-white: #fff;
  --material-gray: #898ba3;
}
